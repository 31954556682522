/* eslint-disable getter-return */
import Grid from "./grid";
import {
  SQRT_3,
  HALF_SQRT_3,
  CELL,
  COLOR,
  CELL_BORDER,
  CELL_SIZE,
  ATTACK_THREAT_LEVEL,
} from "./constants";
import { GLOSSARY } from "./glossary";
import { Game } from "./game";
import { allIn, clamp, range, irange, sleep } from "./util";
import Sound from "./sound";
import { Camera } from "./camera";
import Layers, {
  CellBackgroundLayer,
  CellBorderLayer,
  CellCostLayer,
  CellFadeLayer,
  CellShadowLayer,
  CellSymbolLayer,
  CellWallLayer,
} from "./layers";
import Save from "./save";
import Progress from "./progress";

let idCounter = 0;

const runtimeProps = new Set([
  "$layers",
  "addedTimestamp",
  "activeTimestamp",
  "wonTimestamp",
  "swappedTimestamp",
  "clientDistance",
  "needsLayerUpdate",
  "x",
  "y",
  "xl1",
  "xl2",
  "xr1",
  "xr2",
  "yd",
  "yu",
  "isValidPlace",
  "isDragged",
  "dragStartX",
  "dragStartY",
]);

export default class Cell {
  constructor(type = null, options = {}) {
    this.id = ++idCounter;
    this.creator = options.creator || null;
    this.deck = options.deck || null;
    this.setType(type);
    this.element = options.element || this.element;
    // General state
    this.isVisible = false;
    this.inHand = !!options.inHand;
    if (options.deck) {
      this.setDeck(options.deck);
    }
    this.isValidPlace = false;
    this.isBurned = false;
    this.isDamaged = false;
    this.isRecovering = false;
    this.needsLayerUpdate = false;
    this.addedTimestamp = 0;
    this.activeTimestamp = 0;
    this.wonTimestamp = 0;
    this.swappedTimestamp = 0;
    this.meta = {};
    // Location
    this.q = 0;
    this.r = 0;
    this.s = 0;
    this.calculateXY();
    // Input state
    this.isHovered = false;
    this.isSelected = false;
    this.isHeld = false;
    this.isDragged = false;
    this.dragStartX = 0;
    this.dragStartY = 0;
    this.clientDistance = Infinity;
  }

  get json() {
    return {
      ...Object.fromEntries(
        Object.entries(this).filter(
          ([prop]) =>
            typeof prop !== "function" &&
            !prop.startsWith("on") &&
            !runtimeProps.has(prop)
        )
      ),
      creator: this.creator?.name || null,
      deck: this.deck?.name || null,
    };
  }

  static fromJson(json) {
    const cell = new this(json.type ?? json.element);
    for (const [key, value] of Object.entries(json)) {
      switch (key) {
        case "creator":
        case "deck":
          cell[key] = Game.deckFromJson(value);
          break;
        default:
          cell[key] = value;
          break;
      }
    }
    cell.calculateXY();
    cell.cacheXY();
    cell.setLayers();
    return cell;
  }

  static hydrateSave() {
    if (!Save.cells) return;
    Layers.removeAll();
    let maxId = 0;
    for (const [id, json] of Object.entries(Save.cells)) {
      if (json instanceof Cell) break;
      Save.cells[id] = this.fromJson(json);
      maxId = Math.max(Number(id), maxId);
    }
    idCounter = maxId;
  }

  get glossaryType() {
    return this.type ?? this.element;
  }
  get glossary() {
    return GLOSSARY[this.glossaryType] ?? {};
  }

  // Owner deck
  get isPlayer() {
    return this.deck?.name === "Player";
  }
  get isOpponent() {
    return this.deck?.name === "Opponent";
  }
  get isNeutral() {
    return this.type && !this.deck;
  }

  // Adjacent Cells (Absolute orientation)
  get above() {
    return Grid.get(this.q, this.r - 1);
  }
  get below() {
    return Grid.get(this.q, this.r + 1);
  }
  get upperLeft() {
    return Grid.get(this.q - 1, this.r);
  }
  get upperRight() {
    return Grid.get(this.q + 1, this.r - 1);
  }
  get lowerLeft() {
    return Grid.get(this.q - 1, this.r + 1);
  }
  get lowerRight() {
    return Grid.get(this.q + 1, this.r);
  }

  get adjacentCells() {
    return [
      this.above,
      this.below,
      this.upperLeft,
      this.upperRight,
      this.lowerLeft,
      this.lowerRight,
    ].filter(Boolean);
  }

  get adjacentGaps() {
    return [
      [-1, 1],
      [1, -1],
      [1, 0],
      [0, 1],
      [-1, 0],
      [0, -1],
    ]
      .map(([dq, dr]) => [this.q + dq, this.r + dr])
      .filter(([q, r]) => Grid.get(q, r) === null);
  }

  get contiguous() {
    return this.getContiguous();
  }

  get contiguousAllies() {
    return this.getContiguous((x) => x.deck === this.deck);
  }

  // Adjacent Cells (Owner orientation)
  get forwards() {
    if (this.isPlayer) {
      return this.above;
    }
    return this.below;
  }
  get forwardsLeft() {
    if (this.isPlayer) {
      return this.upperLeft;
    }
    return this.lowerRight;
  }
  get forwardsRight() {
    if (this.isPlayer) {
      return this.upperRight;
    }
    return this.lowerLeft;
  }
  get backwards() {
    if (this.isPlayer) {
      return this.below;
    }
    return this.above;
  }
  get backwardsLeft() {
    if (this.isPlayer) {
      return this.lowerLeft;
    }
    return this.upperRight;
  }
  get backwardsRight() {
    if (this.isPlayer) {
      return this.lowerRight;
    }
    return this.upperLeft;
  }

  // -Move() getters are limited to three directions that go sort of in the
  // correct direction, and only resolve when the route is unambiguous based
  // on all tiles on the grid (regardless of owner)
  get forwardsMove() {
    const { forwards } = this;
    if (this.similarElement(forwards)) {
      return forwards;
    }
    const { forwardsLeft, forwardsRight } = this;
    if (
      this.similarElement(forwardsLeft) &&
      this.similarElement(forwardsRight)
    ) {
      if (this.isPlayer) {
        if (this.x > 0) {
          return forwardsRight;
        } else if (this.x < 0) {
          return forwardsLeft;
        }
      } else {
        if (this.x > 0) {
          return forwardsLeft;
        } else if (this.x < 0) {
          return forwardsRight;
        }
      }
      return;
    } else if (this.similarElement(forwardsLeft)) {
      return forwardsLeft;
    } else if (this.similarElement(forwardsRight)) {
      return forwardsRight;
    }
    if (forwards || forwardsLeft || forwardsRight) {
      return null;
    }
  }
  get backwardsMove() {
    const { backwards } = this;
    if (this.similarElement(backwards)) {
      return backwards;
    }
    const { backwardsLeft, backwardsRight } = this;
    if (
      this.similarElement(backwardsLeft) &&
      this.similarElement(backwardsRight)
    ) {
      if (this.isPlayer) {
        if (this.x > 0) {
          return backwardsLeft;
        } else if (this.x < 0) {
          return backwardsRight;
        }
      } else {
        if (this.x > 0) {
          return backwardsRight;
        } else if (this.x < 0) {
          return backwardsLeft;
        }
      }
      return;
    } else if (this.similarElement(backwardsRight)) {
      return backwardsRight;
    } else if (this.similarElement(backwardsLeft)) {
      return backwardsLeft;
    }
    if (backwards || backwardsLeft || backwardsRight) {
      return null;
    }
  }
  // -Damage() getters are similar to the -Move() getters, but these can
  // resolve to cells with different elements, and will prioritize non-empty
  // cells.
  get forwardsDamage() {
    const { forwards } = this;
    const forwardsHostile = this.hostileTo(forwards);
    if (forwardsHostile) {
      return forwards;
    }
    const { forwardsLeft, forwardsRight } = this;
    const forwardsLeftHostile = this.hostileTo(forwardsLeft);
    const forwardsRightHostile = this.hostileTo(forwardsRight);
    if (forwardsLeftHostile && forwardsLeftHostile === forwardsRightHostile) {
      if (this.isPlayer) {
        if (this.x > 0) {
          return forwardsRight;
        } else if (this.x < 0) {
          return forwardsLeft;
        }
      } else {
        if (this.x > 0) {
          return forwardsLeft;
        } else if (this.x < 0) {
          return forwardsRight;
        }
      }
    }
    if (forwardsLeftHostile) {
      return forwardsLeft;
    } else if (forwardsRightHostile) {
      return forwardsRight;
    }

    if (forwards?.onFriendlyFire) {
      return forwards;
    } else if (
      !forwardsLeft?.onFriendlyFire &&
      !forwardsRight?.onFriendlyFire
    ) {
      return this.forwardsMove;
    } else if (
      Boolean(forwardsLeft?.onFriendlyFire) ^
      Boolean(forwardsRight?.onFriendlyFire)
    ) {
      if (forwardsLeft?.onFriendlyFire) {
        return forwardsLeft;
      }
      return forwardsRight;
    } else if (this.isPlayer) {
      if (this.x > 0) {
        return forwardsRight;
      }
      return forwardsLeft;
    } else {
      if (this.x > 0) {
        return forwardsLeft;
      }
      return forwardsRight;
    }
  }
  get backwardsDamage() {
    const { backwards } = this;
    const backwardsHostile = this.hostileTo(backwards);
    if (backwardsHostile) {
      return backwards;
    }
    const { backwardsLeft, backwardsRight } = this;
    const backwardsLeftHostile = this.hostileTo(backwardsLeft);
    const backwardsRightHostile = this.hostileTo(backwardsRight);
    if (
      backwardsLeftHostile &&
      backwardsLeftHostile === backwardsRightHostile
    ) {
      if (this.isPlayer) {
        if (this.x > 0) {
          return backwardsLeft;
        } else if (this.x < 0) {
          return backwardsRight;
        }
      } else {
        if (this.x > 0) {
          return backwardsRight;
        } else if (this.x < 0) {
          return backwardsLeft;
        }
      }
    }
    if (backwardsRightHostile) {
      return backwardsRight;
    } else if (backwardsLeftHostile) {
      return backwardsLeft;
    }

    if (backwards?.onFriendlyFire) {
      return backwards;
    } else if (
      !backwardsLeft?.onFriendlyFire &&
      !backwardsRight?.onFriendlyFire
    ) {
      return this.backwardsMove;
    } else if (
      Boolean(backwardsLeft?.onFriendlyFire) ^
      Boolean(backwardsRight?.onFriendlyFire)
    ) {
      if (backwardsLeft?.onFriendlyFire) {
        return backwardsLeft;
      }
      return backwardsRight;
    } else if (this.isPlayer) {
      if (this.x > 0) {
        return backwardsLeft;
      }
      return backwardsRight;
    } else {
      if (this.x > 0) {
        return backwardsRight;
      }
      return backwardsLeft;
    }
  }

  // -Solid() getters are similar to the -Move() getters, but these will
  // prioritize non-empty cells.
  get forwardsSolid() {
    const { forwards } = this;
    if (forwards?.type && this.similarElement(forwards)) {
      return forwards;
    }
    const { forwardsLeft, forwardsRight } = this;
    const forwardsLeftSolid =
      forwardsLeft?.type && this.similarElement(forwardsLeft);
    const forwardsRightSolid =
      forwardsRight?.type && this.similarElement(forwardsRight);
    if (forwardsLeftSolid && forwardsLeftSolid === forwardsRightSolid) {
      if (this.isPlayer) {
        if (this.x > 0) {
          return forwardsRight;
        } else if (this.x < 0) {
          return forwardsLeft;
        }
      } else {
        if (this.x > 0) {
          return forwardsLeft;
        } else if (this.x < 0) {
          return forwardsRight;
        }
      }
    }
    if (forwardsLeftSolid) {
      return forwardsLeft;
    } else if (forwardsRightSolid) {
      return forwardsRight;
    }
  }
  get backwardsSolid() {
    const { backwards } = this;
    if (backwards?.type && this.similarElement(backwards)) {
      return backwards;
    }
    const { backwardsLeft, backwardsRight } = this;
    const backwardsLeftSolid =
      backwardsLeft?.type && this.similarElement(backwardsLeft);
    const backwardsRightSolid =
      backwardsRight?.type && this.similarElement(backwardsRight);
    if (backwardsLeftSolid && backwardsLeftSolid === backwardsRightSolid) {
      if (this.isPlayer) {
        if (this.x > 0) {
          return backwardsLeft;
        } else if (this.x < 0) {
          return backwardsRight;
        }
      } else {
        if (this.x > 0) {
          return backwardsRight;
        } else if (this.x < 0) {
          return backwardsLeft;
        }
      }
    }
    if (backwardsRightSolid) {
      return backwardsRight;
    } else if (backwardsLeftSolid) {
      return backwardsLeft;
    }
  }

  adjacent(glossaryType = this.glossaryType) {
    return [
      this.above,
      this.below,
      this.upperLeft,
      this.upperRight,
      this.lowerLeft,
      this.lowerRight,
    ].filter((x) => x?.glossaryType === glossaryType);
  }

  // Determine whether movement into the given element should be allowed
  similarElement(other) {
    if (!other) {
      return false;
    }
    if (
      this.element === "castle" ||
      other.element === "castle" ||
      allIn(["fire", "earth"], this.element, other.element)
    ) {
      return true;
    }
    return this.element === other.element;
  }

  // Determine how aggressively to target another cell
  threatLevel(other) {
    if (!other) {
      return 0;
    }
    if (other.type === null /* && other.element !== "fire" */) {
      return 0;
    }
    if (other.deck === this.deck) {
      return 1;
    }
    if (other.deck === null && other.findersKeepers) {
      return 2;
    }
    return 3;
  }

  // Determine whether it makes sense to attack another cell
  hostileTo(other) {
    return this.threatLevel(other) >= ATTACK_THREAT_LEVEL;
  }
  friendlyTo(other) {
    return this.threatLevel(other) < ATTACK_THREAT_LEVEL;
  }

  // Determine whether the other cell belonged to the same deck
  // before dying
  wouldMourn(other) {
    if (!other) return false;
    if (!other.isRecovering) return false;
    let color;
    if (this.isPlayer) {
      color = COLOR.player;
    } else if (this.isOpponent) {
      color = COLOR.opponent;
    } else {
      color = COLOR.neutral;
    }
    return other.color === color;
  }

  calculateXY() {
    this.x = CELL.BOX_SIZE * 1.5 * this.q;
    this.y = CELL.BOX_SIZE * (HALF_SQRT_3 * this.q + SQRT_3 * this.r);
    this.calculateVertexXY();
  }

  calculateVertexXY() {
    this.xl1 = this.x - CELL.SIZE_COS;
    this.xl2 = this.x - CELL.SIZE;
    this.xr1 = this.x + CELL.SIZE_COS;
    this.xr2 = this.x + CELL.SIZE;
    this.yu = this.y - CELL.SIZE_SIN;
    this.yd = this.y + CELL.SIZE_SIN;
    Game.renderNeeded = true;
  }

  cacheXY() {
    for (const x of [this.xl1, this.xl2, this.xr1, this.xr2]) {
      Grid.fluxCacheX.set(x, x);
    }
    for (const y of [this.yu, this.y, this.yd]) {
      Grid.fluxCacheY.set(y, y);
    }
  }

  async claimAdjacent() {
    await sleep(100);
    await this.spread(async (cell) => {
      if (!cell.type) return false;
      if (!cell.isNeutral || !cell.findersKeepers) {
        return;
      }
      const claims = {
        Player: 0,
        Opponent: 0,
      };
      for (const other of cell.adjacentCells) {
        if (!other.deck) {
          continue;
        }
        claims[other.deck.name]++;
      }
      if (claims.Player === claims.Opponent) {
        return;
      } else if (claims.Player > claims.Opponent) {
        cell.setDeck(Game.player);
      } else {
        cell.setDeck(Game.opponent);
      }
      cell.deck.add(cell.type);
      cell.animateDestroy();
      Sound.play("place");
      await cell.glossary.onFind?.call(cell);
    }, 25);
  }

  async damage(other, friendlyFire = false) {
    const isFriendly =
      other &&
      (other.deck === this.deck || (!other.deck && other.findersKeepers));
    if (
      !other ||
      other.isDamaged ||
      (!other.type && other.element !== "fire") ||
      (!friendlyFire && isFriendly)
    ) {
      if (other && !other.isDamaged) {
        other.animateDestroy(COLOR.invalid);
        Sound.play("damage");
      }
      if (isFriendly) {
        await other.onFriendlyFire?.(this);
      }
      return;
    }
    other.isDamaged = true;
    Sound.play("damage");
    if ((await other.onDamage?.(this)) !== false) {
      await other.destroy();
    }
  }

  async burn(other, friendlyFire = true) {
    if (
      !other ||
      other.glossaryType === "earth" ||
      other.glossary?.fireproof ||
      ["air", "fire"].includes(other.element)
    ) {
      return;
    }
    if (
      other.isBurned ||
      (!friendlyFire &&
        (other.deck === this.deck || (!other.deck && other.findersKeepers)))
    ) {
      if (other && !other.isBurned) {
        other.animateDestroy();
        Sound.play("damage");
      }
      return;
    }
    other.isBurned = true;
    Sound.play("damage");
    if ((await other.onBurn?.(this)) !== false) {
      other.animateDestroy();
      if (other.element === "water") {
        other.setType("Shield");
        await sleep(CELL.DESTROY_DURATION);
        await this.claimAdjacent();
      } else {
        await Promise.all([
          sleep(CELL.DESTROY_DURATION),
          (async () => {
            await other.onDamage?.(this);
            other.set("fire", null);
          })(),
        ]);
      }
    } else {
      other.isBurned = false;
    }
  }

  async destroy(forceRecycle = true) {
    if (!this.type) {
      this.isDamaged = false;
      this.deck = null;
      return;
    }
    const recycle = forceRecycle || this.glossary?.recycle || this.deck?.isBoss;
    const { deck, isDamaged, isPlayer, isOpponent, onDestroy } = this;
    if (deck) {
      deck.unplace(this);
      if (recycle) {
        deck.discard(this);
      } else {
        deck.delete(this);
      }
    }
    // Fancy outro animation
    this.animateDestroy();
    // Clear this out
    this.creator = null;
    this.set(this.element, null);
    Sound.play("destroy");
    await sleep(CELL.DESTROY_DURATION * 0.5);
    // Custom logic for this type of cell
    if (!recycle) {
      await onDestroy?.();
    }
    if (this.isDamaged === true) {
      this.isDamaged = false;
    }
    if (isDamaged) {
      this.deck = null;
      if (!this.type && this.element !== "castle") {
        if (Date.now() - this.swappedTimestamp > 500) {
          this.isRecovering = true;
        }
        if (isPlayer) {
          this.color = COLOR.player;
        } else if (isOpponent) {
          this.color = COLOR.opponent;
        } else {
          this.color = COLOR.neutral;
        }
        this.setLayers();
      }
      this.animateDestroy();
    }
  }

  async animateDestroy(_color = null) {
    Grid.animateFlux(this);
    // Coordinates for vertices
    let { xl1, xl2, xr1, xr2, yu, y: ym, yd } = this;
    xl1 = Grid.fluxCacheX.get(xl1) + CELL_BORDER;
    xl2 = Grid.fluxCacheX.get(xl2) + CELL_BORDER;
    xr1 = Grid.fluxCacheX.get(xr1) - CELL_BORDER;
    xr2 = Grid.fluxCacheX.get(xr2) - CELL_BORDER;
    yu = Grid.fluxCacheY.get(yu) + CELL_BORDER;
    ym = Grid.fluxCacheY.get(ym);
    yd = Grid.fluxCacheY.get(yd) - CELL_BORDER;
    let color;
    if (_color) {
      color = _color;
    } else if (!this.type) {
      if (this.isRecovering || this.element !== "earth") {
        color = this.color || COLOR.neutral;
      } else {
        // color = 'rgba(255,255,255,0.8)';
        color = COLOR.invalid;
        // color = 'black';
      }
    } else if (this.isPlayer) {
      color = COLOR.player;
    } else if (this.isOpponent) {
      color = COLOR.opponent;
    } else {
      color = this.color || COLOR.neutral;
    }
    // Create wall particles
    const deg = (n) => (Math.PI * n) / 180;
    const wallsFor = (x1, y1, x2, y2, a) => {
      return new CellWall(x1, y1, x2, y2, color, deg(a) + range(-0.5, 0.5));
    };
    // this.walls = [
    //   wallsFor(xl2, ym, xl1, yd, 210),
    //   wallsFor(xl1, yd, xr1, yd, 270),
    //   wallsFor(xr1, yd, xr2, ym, 330),
    //   wallsFor(xr2, ym, xr1, yu, 30),
    //   wallsFor(xr1, yu, xl1, yu, 90),
    //   wallsFor(xl1, yu, xl2, ym, 150),
    // ];
    wallsFor(xl2, ym, xl1, yd, 210);
    wallsFor(xl1, yd, xr1, yd, 270);
    wallsFor(xr1, yd, xr2, ym, 330);
    wallsFor(xr2, ym, xr1, yu, 30);
    wallsFor(xr1, yu, xl1, yu, 90);
    wallsFor(xl1, yu, xl2, ym, 150);
    // Wait for animation to end
    await sleep(CELL.DESTROY_DURATION);
  }

  async animateActive() {
    this.activeTimestamp = performance.now();
    this.setLayers();
    await sleep(250);
  }

  moveTo(q, r, playSound = true) {
    Grid.set(this.q, this.r, null);
    Grid.set(q, r, this);
    this.cacheXY();
    if (playSound) {
      Sound.play("move");
    }
  }

  distanceTo(x, y, asGrid = false) {
    const distance = Math.sqrt(
      Math.pow(x - this.x, 2) + Math.pow(y - this.y, 2)
    );
    if (asGrid) {
      return distance / CELL.BOX_SIZE;
    }
    return distance;
  }

  hide() {
    this.isVisible = false;
    Layers.remove(this);
  }

  update(timestamp) {
    if (this.needsLayerUpdate) {
      this.needsLayerUpdate = false;
      this.setLayers();
    }
    if (this.isDragged) {
      this.calculateXY();
    }
    let sizeT;
    if (this.wonTimestamp && this.type !== "Castle") {
      sizeT = clamp(0, (timestamp - this.wonTimestamp) / 1000, 1);
    } else if (this.addedTimestamp) {
      sizeT = 1 - clamp(0, (timestamp - this.addedTimestamp) / 500, 1);
    } else if (this.activeTimestamp) {
      sizeT = 2 * clamp(0, (timestamp - this.activeTimestamp) / 250, 1);
      Game.renderNeeded = true;
    }
    if (sizeT) {
      const lerp = Math.sin(sizeT * Math.PI * 0.5);
      if (this.wonTimestamp) {
        this.adjustedRadius = lerp * 12;
      } else if (this.activeTimestamp) {
        // this.adjustedRadius = lerp * (sizeT - 1) * 4;
        this.adjustedRadius = lerp * -4;
      } else {
        this.adjustedRadius = lerp * 4;
      }
    } else {
      this.adjustedRadius = 0;
    }
    //
    // Handle input
    //
    // let flux = true;
    // let clientEntered = false, clientLeft = false;
    if (
      window.clientMoved ||
      (window.clientPressed && window.lastInputType === "touch")
    ) {
      if (
        (this.inHand ||
          this.isValidPlace ||
          window.lastInputType === "gamepad") &&
        (window.clientHeld ||
          window.clientReleased ||
          window.lastInputType !== "touch")
      ) {
        this.clientDistance = this.distanceTo(window.clientX, window.clientY);
      } else {
        this.clientDistance = Infinity;
      }
      const previous = this.isHovered;
      this.isHovered = this.clientDistance < CELL.SIZE - 4;
      if (previous !== this.isHovered) {
        this.setLayers();
      }
      // if (!previous && this.isHovered) {
      //   // clientEntered = true;
      //   if (window.lastInputType !== 'touch' || !this.inHand) {
      //     // Sound.play('hover');
      //   }
      // } else if (previous && !this.isHovered) {
      //   // clientLeft = true;
      // }
    }
    if (this.inHand) {
      if (window.clientPressed) {
        if (this.isSelected) {
          // Check for selection target
          this.isSelected = false;
          this.isHeld = false;
          this.isDragged = false;
          this.setLayers();
          if (Grid.placeCell(this)) {
            return;
          }
          Sound.play("select");
          if (window.lastInputType === "gamepad") {
            this.isSelected = true;
            this.setLayers();
          }
        } else if (this.isHovered && !Grid.hand?.offsetY) {
          Grid.clearValidPlaces();
          const validPlaces = Grid.getValidPlaces(this);
          if (validPlaces.length) {
            this.isSelected = true;
            this.isHeld = true;
            this.dragStartX = window.clientX;
            this.dragStartY = window.clientY;
            this.setLayers();
            for (const cell of validPlaces) {
              cell.isValidPlace = true;
              cell.setLayers();
            }
            Grid.updateRenderOrder();
            const gamepad = window.clientGamepad;
            if (gamepad) {
              Grid.updateGamepadOptions();
              if (window.lastInputType === "gamepad") {
                this.isHeld = false;
                this.setLayers();
                const last = this.deck.placed.at(-1);
                let best;
                let bestDistance = Infinity;
                const empty = validPlaces.filter((x) => !x.type);
                const filled = validPlaces.filter((x) => x.type);
                for (const cells of [empty, filled]) {
                  for (const cell of cells) {
                    const distance = cell.distanceTo(last.x, last.y);
                    if (
                      distance > bestDistance ||
                      (distance === bestDistance && cell.y >= best.y)
                    ) {
                      continue;
                    }
                    bestDistance = distance;
                    best = cell;
                  }
                  if (bestDistance < CELL_SIZE * 2) {
                    break;
                  }
                }
                if (!best) {
                  best = last;
                }
                gamepad.currentOption = [best.x, best.y];
                best.isHovered = true;
                best.setLayers();
                Camera.shake(this.x * 0.4, this.y * 0.2);
                gamepad.vibrate();
              }
            }
            Sound.play("select");
          }
        }
      } else if (window.clientReleased) {
        if (this.isHeld) {
          if (this.isHovered && !this.isDragged) {
            this.isHeld = false;
          } else {
            // Check for selection target
            this.isSelected = false;
            this.isHeld = false;
            this.isDragged = false;
            this.setLayers();
            if (Grid.placeCell(this)) {
              return;
            }
            Sound.play("select");
            if (window.lastInputType === "gamepad") {
              this.isSelected = true;
            }
          }
        }
      }
      if (this.isHeld) {
        const DRAG_DISTANCE = CELL.SIZE;
        if (
          !this.isDragged &&
          this.isHeld &&
          window.clientMoved &&
          this.clientDistance > DRAG_DISTANCE
        ) {
          // const distance = Math.sqrt(
          //   (window.clientX - this.dragStartX) ** 2 +
          //   (window.clientY - this.dragStartY) ** 2
          // );
          // if (distance >= CELL.SIZE * 0.5) {
          this.isDragged = true;
          this.setLayers();
          // }
        }
        if (this.isDragged) {
          if (this.clientDistance <= DRAG_DISTANCE) {
            this.isDragged = false;
            this.setLayers();
            this.calculateXY();
          } else {
            this.x = window.clientX;
            this.y = window.clientY;
            this.calculateVertexXY();
          }
        }
      }
    }
    if (
      window.clientReleased &&
      window.lastInputType === "touch" &&
      this.isHovered
    ) {
      this.isHovered = false;
      this.setLayers();
    }
  }

  renderAfter() {
    // if (this.isDragged) {
    //   this.calculateXY();
    // }
  }

  renderBefore() {
    // const DRAG_DISTANCE = CELL.SIZE;
    // if (!this.isDragged && this.isHeld && window.clientMoved &&
    //   this.clientDistance > DRAG_DISTANCE) {
    //   // const distance = Math.sqrt(
    //   //   (window.clientX - this.dragStartX) ** 2 +
    //   //   (window.clientY - this.dragStartY) ** 2
    //   // );
    //   // if (distance >= CELL.SIZE * 0.5) {
    //     this.isDragged = true;
    //     this.setLayers();
    //   // }
    // }
    // if (this.isDragged) {
    //   if (this.clientDistance <= DRAG_DISTANCE) {
    //     this.isDragged = false;
    //     this.setLayers();
    //     this.calculateXY();
    //   } else {
    //     this.x = window.clientX;
    //     this.y = window.clientY;
    //     this.calculateVertexXY();
    //   }
    // }
  }

  renderPath(ctx, border = 0, dx = 0, dy = 0) {
    const flux = !(
      window.clientHeld &&
      this.isHeld &&
      (window.clientMoved || this.isDragged) &&
      this.clientDistance > CELL.SIZE_SIN
    );
    let { xl1, xl2, xr1, xr2, yu, y: ym, yd } = this;
    if (flux) {
      xl1 = Grid.fluxCacheX.get(xl1) + border + dx;
      xl2 = Grid.fluxCacheX.get(xl2) + border + dx;
      xr1 = Grid.fluxCacheX.get(xr1) - border + dx;
      xr2 = Grid.fluxCacheX.get(xr2) - border + dx;
      yu = Grid.fluxCacheY.get(yu) + border + dy;
      ym = Grid.fluxCacheY.get(ym) + dy;
      yd = Grid.fluxCacheY.get(yd) - border + dy;
    }
    // ctx.beginPath();
    ctx.moveTo(xl2, ym);
    ctx.lineTo(xl1, yd);
    ctx.lineTo(xr1, yd);
    ctx.lineTo(xr2, ym);
    ctx.lineTo(xr1, yu);
    ctx.lineTo(xl1, yu);
    ctx.closePath();
  }

  // Determines which layers this cell should now be in
  setLayers() {
    Game.renderNeeded = true;
    Layers.remove(this);
    if (!this.isVisible) {
      return;
    }
    // Layers.add(CellProcessingLayer, 'before', this);
    // Layers.add(CellProcessingLayer, 'after', this, false);
    const held = this.isHeld;
    const hand = this.inHand;
    // Background Layer
    let background,
      lineWidth = 0;
    if (!this.type && (this.element === "earth" || this.element === "air")) {
      if (this.isRecovering) {
        background = COLOR.black;
      } else if (this.isHovered) {
        background = "rgba(255,255,255,0.5)";
      } else if (this.isValidPlace) {
        background = "rgba(255,255,255,0.2)";
      } else if (this.element === "earth") {
        background = "rgba(255,255,255,0.1)";
      }
    } else if (this.isPlayer) {
      background = COLOR.player;
      if (this.isValidPlace) {
        if (this.isHovered) {
          background = COLOR.white;
        }
      } else if (this.inHand) {
        if (this.cost > Game.player.hexes) {
          background = COLOR.invalid;
        } else if (!this.isSelected && !this.isHovered) {
          background = COLOR.hand;
        }
      }
    } else if (this.isOpponent) {
      background = COLOR.opponent;
    } else if (this.isHovered) {
      background = "rgba(255,255,255,0.5)";
    } else if (this.isValidPlace) {
      background = "rgba(255,255,255,0.2)";
    } else {
      background = COLOR.black;
    }
    if (
      this.isHovered &&
      !this.inHand &&
      !this.isValidPlace &&
      !this.isNeutral
    ) {
      background = COLOR.white;
    }
    const filled = background?.[0] === "#";
    const elevate = this.wonTimestamp && this.type === "Castle";
    if (this.isHeld) {
      Layers.add(CellBorderLayer, { width: 3, filled, held }, this);
    } else if (this.isValidPlace) {
      Layers.add(CellBorderLayer, { width: 3, filled }, this);
    } else if (this.inHand) {
      if (this.isHovered || this.isSelected) {
        Layers.add(CellBorderLayer, { width: 3, filled, hand }, this);
      } else {
        lineWidth = 8;
      }
    } else if (this.type && !this.isNeutral) {
      lineWidth = 8;
    }
    if (background) {
      Layers.add(
        CellBackgroundLayer,
        {
          color: background,
          lineWidth,
          held: this.isDragged,
          hand,
          elevate,
        },
        this
      );
    }
    // Symbol Layer
    if (this.symbol || this.isRecovering || (!this.type && this.isValidPlace)) {
      let color = null;
      if (background === COLOR.black || this.isRecovering) {
        if (this.isHovered) {
          color = COLOR.white;
        } else if (this.isValidPlace) {
          color = COLOR.player;
        } else {
          color = this.color;
        }
      } else if (!this.type) {
        if (this.isHovered) {
          color = COLOR.white;
        } else {
          color = COLOR.player;
        }
      }
      Layers.add(CellSymbolLayer, { color, held, hand, elevate }, this);
    }
    // Cost Layer
    if (this.inHand && !this.isDragged) {
      Layers.add(CellCostLayer, null, this);
    }
    // Opacity layers
    if (this.wonTimestamp && this.type !== "Castle") {
      Layers.add(
        CellFadeLayer,
        {
          t: this.wonTimestamp,
          c: Game.getWinner() === Game.opponent && COLOR.opponentBackground,
          filled,
          in: false,
        },
        this
      );
    } else if (this.addedTimestamp) {
      Layers.add(
        CellFadeLayer,
        { t: this.addedTimestamp, filled, in: true },
        this
      );
    } else if (this.activeTimestamp) {
      Layers.add(
        CellFadeLayer,
        { t: this.activeTimestamp, active: true },
        this
      );
    } else if (background?.[0] === "#") {
      Layers.add(
        CellShadowLayer,
        {
          color: lineWidth ? background : "#000",
          lineWidth: lineWidth || (this.isDragged ? 0 : 5),
          held: this.isDragged,
          hand,
          elevate,
        },
        this
      );
    }
  }

  setType(type = this.type) {
    this.type = type;
    this.isRecovering = false;
    this.isBurned = false;
    this.isDamaged = false;
    this.meta = {};
    const info = GLOSSARY[type];

    this.symbol = info?.symbol;
    if (info?.enemySymbol && this.isOpponent) {
      this.symbol = info.enemySymbol;
    }

    this.element = info?.element || "earth";
    if (this.element === type) {
      this.type = null;
    }
    this.cost = info?.cost || 0;
    this.color = info?.color || COLOR.neutral;
    this.findersKeepers = info?.findersKeepers !== false;
    this.priority = info?.priority || 0;
    this.onTurn = info?.onTurn?.bind(this);
    this.onEnemyTurn = info?.onEnemyTurn?.bind(this);
    this.onEveryTurn = info?.onEveryTurn?.bind(this);
    this.onDamage = info?.onDamage?.bind(this);
    this.onFriendlyFire = info?.onFriendlyFire?.bind(this);
    this.onBurn = info?.onBurn?.bind(this);
    this.onDestroy = info?.onDestroy?.bind(this);
    info?.onCreate?.call(this);
    this.setLayers();
  }

  setDeck(deck = this.deck) {
    // If this was already placed
    const previous = this.deck;
    previous?.unplace(this);
    this.deck = deck;
    if (!this.inHand) {
      this.deck?.place(this);
    }

    const info = this.glossary;
    if (info?.enemySymbol && this.isOpponent) {
      this.symbol = info.enemySymbol;
    } else if (info?.symbol) {
      this.symbol = info.symbol;
    }

    this.setLayers();
    this.claimAdjacent();
  }

  set(type = this.type, deck = this.deck) {
    this.setType(type);
    this.setDeck(deck);
    Progress.discover(type);
    return this;
  }

  show() {
    this.isVisible = true;
    this.setLayers();
  }

  swapWith(other) {
    if (other.isRecovering) {
      other.isRecovering = false;
    }
    if (other.glossaryType === "fire") {
      other.setType("earth");
    }
    other.swappedTimestamp = Date.now();

    const { q, r } = other;
    Grid.set(q, r, null);
    Grid.set(this.q, this.r, other);
    Grid.set(q, r, this);
  }

  async move({
    /** How many cells to move by each time */
    gait = 1,
    /** Whether to keep going repeatedly as long as possible */
    repeat = false,
    /** Whether to push cells of the same type */
    stack = true,
    /** Whether to push other cells regardless of type */
    push = false,
    /** Whether to stop when there's a hostile cell directly ahead */
    aggressive = false,
    /** Which direction to move (forwards or backwards) */
    direction = "forwards",
    /** A callback for each cell after each step of the move */
    onStep = null,
  } = {}) {
    if (direction !== "forwards" && direction !== "backwards") {
      throw new Error(`Invalid move direction: ${direction}`);
    }
    const key = `move_${this.glossaryType}_${direction}`;
    // console.log({
    //   key,
    //   gait,
    //   direction,
    //   repeat,
    //   stack,
    //   push,
    // });

    if (this.meta[key]) {
      this.meta[key] = false;
      return;
    }

    if (!gait) {
      this.meta[key] = true;
      return;
    }

    let limit = 1;
    const queue = [];
    const nextHelper = (cell) => {
      if (!cell) return;
      if (direction === "forwards") {
        if (this.isPlayer === cell.isPlayer) {
          return cell.forwardsMove;
        } else {
          return cell.backwardsMove;
        }
      }
      if (this.isPlayer === cell.isPlayer) {
        return cell.backwardsMove;
      } else {
        return cell.forwardsMove;
      }
    };
    const next = (cell) => {
      let result = cell;
      for (let i = gait; i--; ) {
        result = nextHelper(result);
      }
      return result;
    };
    const nextDamage = (cell) => {
      if (direction === "forwards") {
        return this.forwards;
      } else {
        return this.backwards;
      }
    };
    for (
      let cell = this;
      cell && this.similarElement(cell);
      cell = next(cell)
    ) {
      if (aggressive && this.hostileTo(nextDamage(cell))) {
        return;
      }
      if (cell.type) {
        if (cell !== this && !push && !stack) return;
        if (queue.includes(cell)) {
          continue;
        }
        queue.unshift(cell);
        if (stack && cell !== this && cell.type === this.type) {
          if (cell.isPlayer === this.isPlayer) {
            cell.meta[key] = true;
            limit++;
          }
        }
        if (cell.type !== this.type && !push) {
          return;
        }
        continue;
      }
      for (const x of queue) {
        const other = next(x);
        if (other.element === "castle") return;
        x.swapWith(other);
        if ((await onStep?.(other, x)) === false) {
          return;
        }
      }
      Sound.play("move");
      if (!repeat && --limit === 0) {
        break;
      }
      await sleep(250);
      cell = queue[0];
    }
  }

  async attack({
    /** Whether to push cells of the same type */
    stack: _stack = true,
    /** Which direction to move (forwards or backwards) */
    direction = "forwards",
  } = {}) {
    if (direction !== "forwards" && direction !== "backwards") {
      throw new Error(`Invalid attack direction: ${direction}`);
    }

    const next = (cell) => {
      if (!cell) return;
      if (direction === "forwards") {
        if (this.isPlayer === cell.isPlayer) {
          return cell.forwardsMove;
        } else {
          return cell.backwardsMove;
        }
      }
      if (this.isPlayer === cell.isPlayer) {
        return cell.backwardsMove;
      } else {
        return cell.forwardsMove;
      }
    };

    if (_stack) {
      let stack = 0;
      let front = this.forwardsMove;
      for (
        ;
        front?.type === this.type && front.deck === this.deck;
        front = next(front), ++stack
      );
      if (stack) {
        let other = front;
        for (; other && stack--; ) {
          other = next(other);
        }
        if (other) {
          return this.damage(other);
        }
      }
    }

    // Destroy adjacent enemies (one direction)
    return this.damage(this.forwardsDamage);
  }

  /**
   * Calls the callback on every unique cell recursively outward from
   * this cell, until the callback returns false or every cell has been
   * through the callback. The stagger is how long to wait between calls.
   */
  spread(callback, stagger = 250) {
    let cancelled = false;
    const cancel = () => (cancelled = true);
    const searched = new Set();
    const helper = async (cell, path = []) => {
      if (stagger) await sleep(stagger);
      await Promise.all(
        cell.adjacentCells.map(async (other) => {
          if (cancelled) return;
          if (!other) return;
          if (searched.has(other)) return;
          searched.add(other);
          if ((await callback(other, { cancel, path })) === false) return;
          if (cancelled) return;
          return helper(other, [...path, other]);
        })
      );
    };
    return helper(this);
  }

  /**
   * Gets all cells that are touching this cell and meet
   * the condition (if provided)
   */
  getContiguous(condition = null) {
    const result = [this];
    const searched = new Set(result);
    const helper = async (cell) => {
      for (const other of cell.adjacentCells) {
        if (searched.has(other)) continue;
        searched.add(other);
        if (!other?.type || !this.similarElement(other)) continue;
        if (condition && !condition(other)) continue;
        result.push(other);
        helper(other);
      }
    };
    helper(this);
    return result;
  }

  autoPlace(options = {}) {
    // console.log(`AUTOPLACE ${this.type}`);
    const places = Grid.getValidPlaces(this).filter(
      (x) => x.type !== this.type && !options.avoid?.includes(x)
    );
    const isFocusType = Game.focusTypes.includes(this.type);
    let destination;
    const vibes = places
      .map((x) => [x, this.vibeTowards(x, options)])
      .sort((a, b) => b[1] - a[1]);
    // console.log(vibes);
    const min = vibes[vibes.length - 1]?.[1] ?? 0;
    const max = vibes[0]?.[1] ?? 0;
    const luck = Math.round(this.deck.ai.luck * Math.random());
    const intellect =
      this.deck.ai.intellect + irange(5, 10) * isFocusType + luck;
    // console.log(`${intellect} intellect (w/ ${luck} luck)`);
    const minVibe = Math.min(luck, 0);
    const maxIndex = vibes.findLastIndex((x) => x[1] >= minVibe);
    const index = clamp(0, (10 - intellect) / 20, 1);
    const theVibe = vibes[Math.round(index * maxIndex)];
    if (!theVibe || theVibe[1] < Math.min(luck, 0)) {
      console.log(
        `Failed to autoplace ${this.type}. The vibes were off (${
          theVibe?.[1] ?? "n/a"
        }).`
      );
      return false;
    }
    destination = theVibe[0];
    console.log(
      `Placing ${this.type} at [${destination.q},${destination.r}] with ${theVibe[1]} vibes (of ${vibes.length} places from ${min} to ${max})`
    );
    destination.isValidPlace = true;
    Grid.placeCell(this, destination);
    return destination;
  }

  vibeTowards(other, options = {}) {
    // The general vibe is based on tags and properties, instead of
    // cell-type-specific logic
    let generalVibe = 0;

    // Gifts
    if (other.adjacentCells?.some((x) => x.isNeutral && x.findersKeepers)) {
      generalVibe += 10;
    }

    // Building off of other new cells
    const {
      forwards,
      forwardsLeft,
      forwardsRight,
      backwards,
      backwardsLeft,
      backwardsRight,
    } = other;
    for (const x of options.blocked || []) {
      switch (x) {
        case backwards:
          generalVibe += 15;
          break;
        case backwardsLeft:
        case backwardsRight:
          generalVibe += 13;
          break;
        case forwardsLeft:
        case forwardsRight:
          generalVibe += 8;
          break;
        case forwards:
          generalVibe += 5;
          break;
        default:
          break;
      }
    }

    // Helper for determining whether a location is ripe for hurt
    const goodForDamage = (place) => {
      return this.hostileTo(place) || place?.isRecovering;
    };

    // Tags for this cell
    const tags = new Set(this.glossary.tags ?? []);
    const otherTags = new Set(other.glossary.tags ?? []);
    const { forwardsDamage, forwardsMove, backwardsMove } = other;
    if (
      tags.has("attack") &&
      !otherTags.has("attack") &&
      goodForDamage(forwardsDamage)
    ) {
      generalVibe += 15;
      if (forwardsDamage?.type !== "Barrier") {
        generalVibe += 20;
      }
    }
    if (tags.has("move") && !otherTags.has("move") && !forwardsMove?.type) {
      generalVibe += 15;
    }
    if (tags.has("strong") && !otherTags.has("strong")) {
      if (
        other.adjacentCells.some(
          (x) => x.type === "Castle" && x.deck === this.deck
        )
      ) {
        generalVibe += 30;
      }
      if (goodForDamage(forwardsDamage)) {
        generalVibe += 15;
      }
    }
    if (
      tags.has("stack") &&
      !otherTags.has("stack") &&
      other.type !== this.type
    ) {
      if (forwardsMove?.type === this.type) {
        generalVibe += 20;
      }
      if (backwardsMove?.type === this.type) {
        generalVibe += 20;
      }
    }

    // Tags for the cell being replaced
    if (otherTags.has("attack") && !tags.has("attack") && !tags.has("move")) {
      if (goodForDamage(forwardsDamage)) {
        generalVibe -= 5;
      } else if (
        otherTags.has("stack") &&
        (forwardsMove?.type === other.type ||
          backwardsMove?.type === other.type)
      ) {
        generalVibe -= 10;
      } else if (forwardsMove?.type === null) {
        if (otherTags.has("move")) {
          generalVibe -= 20;
        } else {
          generalVibe -= 10;
        }
      } else {
        generalVibe += 20;
      }
    }

    // These vibes are derived from the glossary
    const vibe = this.glossary.vibe?.call(this, other) || 0;
    const replaceVibe = other.glossary.replaceVibe?.call(other, this) || 0;

    // Combine these vibes with the vibes of all adjacent cell's adjacent vibes
    return other.adjacentCells
      .map((x) => x.glossary.adjacentVibe?.call(x, other, this) || 0)
      .reduce((sum, x) => sum + x, generalVibe + vibe + replaceVibe);
  }
}

class CellWall {
  constructor(x1, y1, x2, y2, color, angle) {
    this.x1 = x1;
    this.y1 = y1;
    this.x2 = x2;
    this.y2 = y2;
    this.color = color;
    this.angle = angle;
    const velocity = CELL.DESTROY_VELOCITY * range(0.75, 1.5);
    this.vx = velocity * Math.cos(angle);
    this.vy = 6 + velocity * -Math.sin(angle);
    this.t = 100 * Math.round(performance.now() / 100);
    Layers.add(CellWallLayer, { t: this.t, c: this.color }, this);
  }

  renderPath(ctx, lerp) {
    ctx.moveTo(this.x1 + this.vx * lerp, this.y1 + this.vy * lerp);
    ctx.lineTo(this.x2 + this.vx * lerp, this.y2 + this.vy * lerp);
  }
}
