import { Camera } from "./camera";
import Cell from "./cell";
import { COLOR, HEIGHT, MAX_R, LEFT_X, RIGHT_X, BOTTOM_Y } from "./constants";
import { CURIOSITIES } from "./curiosities";
import { BIOMES } from "./biomes";
import { Game } from "./game";
import { GLOSSARY } from "./glossary";
import Grid from "./grid";
import Layers from "./layers";
import Save from "./save";
import Sound from "./sound";
import { choose, repeat, sleep } from "./util";
import { isChrome } from "./userAgent";

const OFFSET_DURATION = 750;
const OFFSET_OFFSCREEN = 196;

const Hand = new (class {
  constructor() {
    Grid.hand = this;
    this.displayHexes = 0;
    this.lerp = 1;
    this.offsetYStart = 0;
    this.roundStartT = 0;
    this.isGameOver = false;
    this.cells = [];
  }

  get json() {
    return {
      offsetY: this.offsetY || 0,
      isGameOver: this.isGameOver,
      cells: this.cells.map((x) => x.id),
    };
  }
  set json(json) {
    if (!json) return;
    this.offsetY = json.offsetY || 0;
    this.isGameOver = json.isGameOver;
    this.cells = json.cells.map((x) => Save.cells[x]);
    this.calculateXY();
  }

  async generateGrid() {
    const beCurious = Game.level % 5 === 4;
    // // TEMP: always generate a curiosity
    // const beCurious = true;
    if (beCurious) {
      // Detect whether any curiosities are suited to the current focus
      let key;
      let bestMatch = 0;
      const bestKeys = [];
      const inHand = Array.from(new Set(Game.player.starting));
      if (
        Game.completedCuriosities.length === Object.keys(CURIOSITIES).length
      ) {
        Game.completedCuriosities.splice(0);
      }
      for (const [k, { hand }] of Object.entries(CURIOSITIES)) {
        if (Game.completedCuriosities.includes(k)) continue;
        const unique = new Set(hand);
        const match =
          3 * Game.focusTypes.filter((x) => unique.has(x)) +
          inHand.reduce((sum, x) => sum + Number(unique.has(x)), 0);
        if (!match) continue;
        // console.log({ key: k, match, bestMatch });
        if (match > bestMatch) {
          bestMatch = match;
          bestKeys.splice(0);
          bestKeys.push(k);
          continue;
        }
        bestKeys.push(k);
      }
      if (bestKeys.length) {
        key = choose(bestKeys);
      }

      if (!key) {
        key = "treasure";
      }
      // // TEMP: always set a specific curiosity
      // key = "field";
      this.curiosity = key;
      console.log('Generating curiosity: "%s"', key);
      Game.curiosity = { ...CURIOSITIES[key], key };
      Grid.generateEmpty();
      await Game.curiosity.generate?.();
    } else {
      Game.curiosity = null;
      this.chooseBiome();
      Grid.generate();
    }
    this.roundStartT = Date.now();
  }

  /**
   * Sets the biome randomly, but with some constraints based
   * on the current focus type(s).
   */
  chooseBiome() {
    const requiredElements = [];
    const blockedElements = [];
    if (Game.focusTypes.some((x) => GLOSSARY[x]?.element === "water")) {
      requiredElements.push("water");
    } else if (Game.focusTypes.some((x) => GLOSSARY[x]?.element === "air")) {
      requiredElements.push("air");
    } else if (
      // If there aren't any non-earth cells in the focus types nor the player's deck
      ![...Game.focusTypes, ...Game.player.starting].some((x) => {
        const info = GLOSSARY[x];
        if (!info) return false;
        return (
          (info.element ?? "earth") !== "earth" || info.tags?.includes("fire")
        );
      })
    ) {
      requiredElements.push("earth");
      // blockedElements.push("water", "air");
    }
    const possible = BIOMES.filter((biome) => {
      if (Game.level === 1 && biome.id !== "earth") {
        return false;
      }
      if (!requiredElements.every((x) => biome.tags.includes(x))) {
        return false;
      }
      if (blockedElements.some((x) => biome.tags.includes(x))) {
        return false;
      }
      if (!biome.condition) return true;
      return Boolean(biome.condition(Game));
    }).flatMap((x) => repeat(x, x.weight ?? 1));
    if (!possible.length) possible.push(BIOMES[0]);
    Game.biome = choose(possible);

    // // TEMP: Hardcode a specific biome
    // Game.biome = BIOMES.find((x) => x.id === "pepper");
  }

  async recover() {
    await Save.load();

    const state = Save.json;
    Cell.hydrateSave();

    Game.json = state.game;
    const { biome, curiosity } = Game;
    Game.biome = BIOMES.find((x) => x.id === biome?.id);
    Game.curiosity = CURIOSITIES[curiosity?.key] ?? null;
    if (curiosity?.key) Game.curiosity.key = curiosity.key;
    Grid.json = state.grid;
    this.json = state.hand;
    Grid.updateGamepadOptions();

    // console.log({ state });

    if (this.isGameOver) {
      const winner = Game.getWinner();
      Grid.setWinner(winner);
      setTimeout(() => winner.setBackground?.(), 5);
      Camera.set(null, Camera.defaultY + 2 * winner.placed[0].y, 0);
    }
  }

  save() {
    if (Game.opponent.isInTurn) {
      console.log("skipping save during ai turn");
      return;
    }
    console.log("saving!");
    Save.set({
      state: Game.state || Save.state,
      game: Game.json,
      grid: Grid.json,
      hand: this.json,
      cells: Object.fromEntries(
        [...Grid.existing, ...this.cells].map((x) => [x.id, x.json])
      ),
    });
  }

  startTurn() {
    Game.player.startTurn(Grid.availableElements);
    this.cells = Game.player.drawn.map(
      (x) => new Cell(x, { deck: Game.player, inHand: true })
    );
    this.calculateXY();
    this.offsetY = 0;
    Grid.updateGamepadOptions();
  }

  async endTurn() {
    // If the turn is already ending, stop here
    if (this.offsetY) {
      return;
    }
    this.offsetY = OFFSET_OFFSCREEN;
    Sound.play("select");
    Camera.shake(RIGHT_X * 0.8, BOTTOM_Y * 0.5);
    if (window.lastInputType === "gamepad") {
      window.clientGamepad?.vibrate();
    }
    await sleep(OFFSET_DURATION);
    this.cells.splice(0).forEach((x) => Layers.remove(x));
    Camera.set(null, HEIGHT * 0.5, 750);
    await Grid.updateRecovery();
    await Game.player.endTurn();
    Grid.updateGamepadOptions();
    await this.everyTurn();
    if (await this.matchHasEnded()) {
      return;
    }
    await this.aiTurn();
    await this.everyTurn();
    if (await this.matchHasEnded()) {
      return;
    }
    await Game.curiosity?.onTurn?.();
    Camera.reset(500);
    this.startTurn(Grid.availableElements);
    this.save();
  }

  async aiTurn() {
    const deck = Game.opponent;
    if (!Game.curiosity || deck.placed.some((x) => x.onTurn)) {
      deck.startTurn(Grid.availableElements);
    }
    if (Game.curiosity) {
      await sleep(500);
      await Grid.updateRecovery();
      await deck.endTurn();
      return;
    }
    await sleep(1000);
    let placedAny = false;
    // This keeps track of which places have been set this turn so that
    // they're not immediately replaced
    const avoid = [];
    // Attemptable avoids attempting the same type twice in a row & types
    // that are too expensive to place now
    let attemptable = [];
    const refreshAttemptable = () => {
      attemptable = deck.drawn.flatMap((type) => {
        const cost = GLOSSARY[type]?.cost ?? 0;
        if (cost > deck.hexes) return [];
        return repeat(type, cost);
      });
    };
    refreshAttemptable();
    // Try up to 50 times to place things (one type might have bad vibes
    // while another doesn't)
    for (let i = 50; i--; ) {
      const type = choose(attemptable);
      if (!type) break;
      const place = new Cell(type, { deck, inHand: true }).autoPlace({ avoid });
      if (place === false) {
        attemptable = attemptable.filter((x) => x !== type);
        if (!attemptable.length) break;
        continue;
      }
      avoid.push(place);
      placedAny = true;
      refreshAttemptable();
      await sleep(750);
    }
    if (!placedAny) {
      await sleep(500);
    }
    await sleep(500);
    await Grid.updateRecovery();
    await deck.endTurn();
  }

  async everyTurn() {
    // Cells that should go every turn (regardless of deck) go last
    const groups = new Map();
    for (const cell of Grid.existing) {
      if (!cell.onEveryTurn) continue;
      let group = groups.get(cell.priority);
      if (!group) {
        groups.set(cell.priority, (group = []));
      }
      group.push(cell);
    }
    for (const priority of [...groups.keys()].sort((a, b) => b - a)) {
      await Promise.all(
        groups.get(priority).map(async (cell) => {
          await cell.animateActive();
          await cell.onEveryTurn?.();
        })
      );
      // Skip waiting for Exits
      if (priority === 500) continue;
      await sleep(500);
    }
  }

  async matchHasEnded() {
    const winner = Game.getWinner();
    if (!winner) {
      return false;
    }
    // Make sure the background matches the winner
    winner.setBackground?.();
    // Pause for a mere moment
    await sleep(1000);
    // Convert the loser's pieces
    await Grid.setWinner(winner);
    // Zoom in on the loser
    Camera.allowTransform = false;
    Camera.setTransform?.(`perspective(1000px) translateZ(-32px)`);
    clearTimeout(Camera.transformTimeout);
    // Actually handle doing the next thing
    if (winner === Game.opponent) {
      await Camera.set(null, Camera.defaultY + 2 * winner.placed[0].y);
      this.isGameOver = true;
      this.offsetY = 0;
      Grid.updateGamepadOptions();
      Camera.allowTransform = true;
      Camera.shake(0, 0);
      this.save();
      return true;
    } else {
      await Camera.set(null, Camera.defaultY + 2 * winner.placed[0].y);
    }
    // After defeating a boss, add two of each focus type
    if (Game.isBoss) {
      for (const type of Game.focusTypes) {
        for (let i = 2; i--; ) {
          Game.player.add(type);
        }
      }
    }
    // After completing a curiosity, add it to the strike list
    if (Game.curiosity?.key) {
      Game.completedCuriosities.push(Game.curiosity.key);
    }
    Game.level++;
    if ((Game.level - 1) % 5 === 0) {
      Game.generateStage();
    }
    this.chooseBiome();
    Game.generate();
    this.generateGrid();
    Camera.reset();
    Grid.updateFluxCache(performance.now());
    Grid.animateIn();
    Camera.allowTransform = true;
    Camera.shake(0, 0);
    await sleep(500);
    this.startTurn(Grid.availableElements);
    this.save();
    return true;
  }

  async restartRun() {
    if (!this.isGameOver) {
      return;
    }
    this.isGameOver = false;
    this.offsetYStart = this.offsetY = OFFSET_OFFSCREEN;
    await Grid.restartRun();
  }

  calculateXY() {
    for (let i = this.cells.length; i--; ) {
      const cell = this.cells[i];
      let q = Math.ceil(i / 2),
        r = 0;
      if (i % 2) {
        q *= -1;
      } else {
        r = -q;
      }
      cell.q = q;
      cell.r = MAX_R + 2.25 + r;
      cell.s = -cell.q - cell.r;
      cell.calculateXY();
      cell.cacheXY();
      cell.show();
    }
  }

  remove(cell) {
    cell.hide();
    if (!cell.isPlayer) {
      return cell.deck?.drawn.indexOf(cell.type);
    }
    const index = this.cells.indexOf(cell);
    if (index === -1) {
      throw new Error(
        `Could not remove cell from Hand because it was not in Hand`
      );
    }
    this.cells.splice(index, 1);
    return index;
  }

  update(timestamp, delta) {
    // Update offset
    if (this.offsetY !== this.offsetYStart) {
      if (!this.offsetT) {
        this.offsetT = timestamp;
      }
      const t = (timestamp - this.offsetT) / OFFSET_DURATION;
      if (t >= 1) {
        this.lerp = 1;
        Game.handY = this.offsetYStart = this.offsetY;
        this.offsetT = 0;
      } else {
        this.lerp = Math.sin(t * Math.PI * 0.5) ** 2;
        Game.handY =
          this.offsetYStart + this.lerp * (this.offsetY - this.offsetYStart);
      }
    }
    // Update hexes
    if (Game.player.hexes !== this.displayHexes) {
      const hexDelta = Game.player.hexes - this.displayHexes;
      if (Math.abs(hexDelta) < 0.1) {
        this.displayHexes = Game.player.hexes;
      } else if (delta < 100) {
        this.displayHexes += hexDelta * delta * 0.008;
      }
    }
    // Cells
    for (const cell of this.cells) {
      cell.update(timestamp);
    }
  }

  render(ctx) {
    // Update offset
    const offsetY = Game.handY;
    const { lerp } = this;
    if (this.offsetT) {
      if (this.offsetY) {
        ctx.globalAlpha = 1 - lerp;
      } else {
        ctx.globalAlpha = lerp;
      }
    }
    if (this.isGameOver) {
      this.renderGameOver(ctx);
    }
    // Cells
    let hovered, selected;
    for (const cell of this.cells) {
      if (cell.isHovered) {
        hovered = cell;
      }
      if (cell.isSelected) {
        selected = cell;
      }
    }
    if (Game.player.isInTurn) {
      // Current hexes
      ctx.fillStyle = COLOR.hand;
      ctx.textAlign = "right";
      ctx.textBaseline = "middle";
      ctx.font = `900 32px "Font Awesome 6 Pro"`;
      ctx.fillText("\uf312", LEFT_X + 8, BOTTOM_Y - 24 + offsetY);
      ctx.textAlign = "left";
      ctx.font = `40px "Bungee", Roboto, Helvetica, sans-serif`;
      ctx.fillText(
        Math.round(this.displayHexes),
        LEFT_X + 16,
        BOTTOM_Y - 24 + (isChrome ? 2 : 0) + offsetY
      );
      // End turn
      if (
        window.clientX > RIGHT_X - 72 &&
        window.clientX < RIGHT_X + 32 &&
        window.clientY > BOTTOM_Y - 48 &&
        window.clientY < BOTTOM_Y + 8
      ) {
        if (window.lastInputType !== "touch" || window.clientHeld) {
          ctx.fillStyle = COLOR.a;
        }
        if (window.clientPressed) {
          this.endTurn();
        }
      } else {
        ctx.fillStyle = COLOR.hand;
      }
      ctx.textAlign = "right";
      // ctx.textBaseline = 'middle';
      ctx.font = `18px "Bungee", Roboto, Helvetica, sans-serif`;
      ctx.fillText("END", RIGHT_X - 16, BOTTOM_Y - 34 + offsetY * 1.75);
      ctx.fillText("TURN", RIGHT_X - 16, BOTTOM_Y - 14 + offsetY * 1.75);
      ctx.textAlign = "left";
      ctx.font = `900 32px "Font Awesome 6 Pro"`;
      if (window.lastInputType === "gamepad") {
        ctx.fillStyle = COLOR.x;
        ctx.fillText("\ue12e", RIGHT_X - 8, BOTTOM_Y - 24 + offsetY * 1.75);
        if (hovered || selected) {
          ctx.textAlign = "center";
          ctx.font = `900 24px "Font Awesome 6 Pro"`;
          ctx.lineWidth = 5;
          ctx.strokeStyle = "black";
          let icon, x, y;
          if (selected) {
            ctx.fillStyle = COLOR.b;
            icon = "\ue0fd";
            ({ x, y } = selected);
            ctx.strokeText(icon, x, y + 40 + offsetY);
            ctx.fillText(icon, x, y + 40 + offsetY);
            if (hovered) {
              hovered = null;
            } else {
              for (const cell of Grid.existing) {
                if (!cell.isHovered) {
                  continue;
                }
                hovered = cell;
                break;
              }
            }
          }
          if (hovered) {
            if (hovered.inHand || hovered.isValidPlace) {
              ctx.fillStyle = COLOR.a;
            } else {
              ctx.fillStyle = "gray";
            }
            icon = "\ue0f7";
            ({ x, y } = hovered);
            ctx.strokeText(icon, x, y + 40 + offsetY);
            ctx.fillText(icon, x, y + 40 + offsetY);
          }
        }
      } else {
        // ctx.fillText('\uf00c', RIGHT_X - 12, BOTTOM_Y);
        // ctx.fillText('\uf101', RIGHT_X - 12, BOTTOM_Y + offsetY * 1.75);
        ctx.fillText("\uf058", RIGHT_X - 8, BOTTOM_Y - 24 + offsetY * 1.75);
      }
    }
    const duration = 3000;
    const roundT = this.isGameOver
      ? duration * 0.5
      : Date.now() - this.roundStartT;
    if (roundT <= duration) {
      const t = Math.sin(Math.PI * (0.25 + (0.75 * roundT) / duration));
      ctx.globalAlpha = 0.6 * t;
      ctx.font = `160px "Bungee", Roboto, Helvetica, sans-serif`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      if (this.isGameOver) {
        ctx.fillStyle = COLOR.opponent;
      } else {
        ctx.fillStyle = COLOR.black;
      }
      ctx.fillText(Game.level, 0, this.isGameOver ? -Camera.y + 230 : 0);
    }
    ctx.globalAlpha = 1;
  }

  renderGameOver(ctx) {
    const offsetY = Game.handY;
    let y = -Camera.y + HEIGHT * 0.8 + offsetY + 32;

    if (
      window.clientPressed &&
      window.clientX > LEFT_X &&
      window.clientX < RIGHT_X &&
      window.clientY > y - 48 &&
      window.clientY < y + 96
    ) {
      this.restartRun();
    }

    ctx.fillStyle = COLOR.opponent;
    ctx.textAlign = "center";
    ctx.font = `96px "Bungee", Roboto, Helvetica, sans-serif`;
    y = -Camera.y + HEIGHT * 0.5 + offsetY * 0.5;
    ctx.textBaseline = "bottom";
    ctx.fillText("GAME", 0, y + 84 - 8);
    ctx.textBaseline = "top";
    ctx.fillText("OVER", 0, y + 84 + 8);

    ctx.fillStyle = "white";
    // ctx.textAlign = 'right';
    ctx.textBaseline = "middle";
    ctx.font = `32px "Bungee", Roboto, Helvetica, sans-serif`;
    y = -Camera.y + HEIGHT * 0.8 + offsetY;
    ctx.fillText("TRY AGAIN", 32, y + 32);
    // ctx.textAlign = 'left';
    ctx.font = `900 32px "Font Awesome 6 Pro"`;
    if (window.lastInputType === "gamepad") {
      ctx.fillStyle = COLOR.a;
      ctx.fillText("\ue0f7", -96, y + 32);
    } else {
      // ctx.fillText('\uf00c', RIGHT_X - 12, BOTTOM_Y);
      // ctx.fillText('\uf101', RIGHT_X - 32, y);
      ctx.fillText("\uf2f9", -96, y + 32);
    }
  }
})();

// Testing helper
window.Hand = Hand;

export default Hand;
