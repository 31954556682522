import { useCallback, useMemo, useState } from "react";
import { Game } from "./game";
import Grid from "./grid";
import Sound from "./sound";
import { GLOSSARY } from "./glossary";
import { COLOR } from "./constants";
import Progress from "./progress";

const getTitle = (type) => type.replace(/[A-Z]/g, " $&");

const HIDDEN_TAGS = new Set(["fire", "neat"]);
const SINGULAR_TAGS = new Set(["fire", "strong", "utility"]);
function getTags(type) {
  const info = GLOSSARY[type];
  if (info.storable === false && type !== "Barrier") {
    return [];
  }
  return [
    info.element ?? "earth",
    ...(info.tags ?? [])
      .filter((x) => !HIDDEN_TAGS.has(x))
      .map((x) => (SINGULAR_TAGS.has(x) ? x : `${x}s`)),
    info.fireproof ? "fireproof" : "",
  ].filter(Boolean);
}

export function Hexylpedia({ onClose }) {
  const [filter, setFilter] = useState("Here");
  const [type, setType] = useState("");
  const back = useCallback(() => {
    setType("");
    Sound.play("select");
  }, []);

  return (
    <section className="hexylpediaFrame">
      <nav className={type ? "" : "withTitle"}>
        <span>{type ? "" : "Hexylpedia"}</span>
        <button
          className="iconButton"
          onClick={type ? back : onClose}
          data-icon={"\uf00d"}
        ></button>
      </nav>
      <div className="hexylpediaContent">
        {type ? (
          <Entry type={type} />
        ) : (
          <Index filter={filter} setFilter={setFilter} setType={setType} />
        )}
      </div>
    </section>
  );
}

function Index({ filter, setFilter, setType }) {
  const orderedTypes = useMemo(
    () =>
      Object.entries(GLOSSARY)
        .filter(([_, info]) => info.number)
        .sort((a, b) => a[1].number - b[1].number)
        .map(([type]) => type),
    []
  );
  const filteredTypes = useMemo(() => {
    switch (filter) {
      case "Here": {
        const here = new Set([
          ...Grid.existing.map((x) => x.glossaryType),
          ...(Game.player.isInTurn ? Game.player.drawn : []),
          ...(Grid.filter((x) => x.isRecovering).length ? ["Grave"] : []),
        ]);
        if (here.has("Exit")) here.add("Castle");
        return orderedTypes.filter((x) => here.has(x));
      }
      case "Yours": {
        const deck = new Set(Game.player.starting);
        deck.add("Barrier");
        return orderedTypes.filter((x) => deck.has(x));
      }
      default:
        return orderedTypes;
    }
  }, [filter, orderedTypes]);

  return (
    <>
      <div className="filters">
        {["All", "Here", "Yours"].map((x) => (
          <button
            className={x === filter ? "selected" : ""}
            key={x}
            onClick={() => setFilter(x)}
          >
            {x}
          </button>
        ))}
      </div>
      <div className="cellList">
        {filteredTypes.map((type) => (
          <button key={type} onClick={() => setType(type)}>
            <Cell type={type} />
          </button>
        ))}
      </div>
    </>
  );
}

function Entry({ type }) {
  let info = GLOSSARY[type];
  let tags = useMemo(() => getTags(type), [type]);

  const isDiscovered = Progress.discovered.has(type);
  if (!isDiscovered) {
    type = "unknown";
    info = {
      description: `Keep exploring to learn more about this cell`,
    };
    tags = tags
      .map((_, i) => ["???", "????", "??", "?????"][i])
      .filter(Boolean);
  }

  return (
    <div className="hexylpediaEntry">
      {/* {info.storable !== false || type === "Barrier" ? (
        <div className="cellCost" data-cost={String(info.cost ?? 0)} />
      ) : null} */}
      <header>
        <div className="cellType">
          {isDiscovered ? getTitle(type) : "Unknown"}
        </div>
        <div className="cellTagList">
          {tags.map((x) => (
            <div className="cellTag" key={x}>
              {x}
            </div>
          ))}
        </div>
      </header>
      <div className="bigCell">
        <Cell type={type} />
      </div>
      <div className="cellDescription">{info.description ?? ""}</div>
    </div>
  );
}

function Cell({ type }) {
  let info = GLOSSARY[type];

  const isDiscovered = Progress.discovered.has(type);
  if (!isDiscovered) {
    info = {
      symbol: "?",
    };
  }

  let background = COLOR.black;
  let color = info.color ?? COLOR.white;
  let symbol = info.symbol || "";
  let scale = 0.6;
  if (!isDiscovered) {
    // noop
  } else if (type === "earth") {
    background = "rgba(255, 255, 255, 0.2) var(--lines-pattern)";
  } else if (type === "air") {
    background = "rgba(255, 255, 255, 0.2)";
    color = "var(--background)";
    symbol = "\uf312";
    scale = 1.25;
  }

  return (
    <div
      className={`cell ${type}Type`}
      data-symbol={symbol}
      style={{
        "--cell-background": background,
        "--cell-scale": `${scale}em`,
        // backgroundImage: 'var(--)',
        color,
      }}
    />
  );
}
