const Progress = new (class {
  constructor() {
    this.load();
  }

  get json() {
    return {
      discovered: Array.from(this.discovered),
    };
  }

  getStorage() {
    return JSON.parse(localStorage.getItem("hexyl-progress") || "{}");
  }

  load() {
    const stored = this.getStorage();
    this.discovered = new Set(stored.discovered || []);
  }

  saveStorage(json) {
    localStorage.setItem("hexyl-progress", JSON.stringify(json));
  }

  // set(name, value) {
  //   if (name in this.json === false) {
  //     return;
  //   }
  //   this[name] = value;
  //   this.saveStorage({
  //     ...this.getStorage(),
  //     [name]: value,
  //   });
  //   return value;
  // }

  discover(type) {
    this.discovered.add(type);
    this.saveStorage(this.json);
  }
})();

window.Progress = Progress;

export default Progress;
